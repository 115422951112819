
.appointment{
    height: 100vh;
}

.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    /* background-color: rgba(29, 18, 71, 0.3); */
    border-radius: 5px;
    border: 2px solid rgba(29, 18, 71, 0.3);
  }

  .form-label {
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    text-align: center;
    padding: 10px;

  }

  .form-input{
    font-size: medium;
    font-weight: bold;
    border-radius: 5px;
    
  }

  .input-box{
    padding: 10px;
    border-radius: 5px;
    border: 2px solid rgba(29, 18, 71, 0.3);
  }

  .form-button{
    border-radius: 5px; /* Rounded corners */
    transition: background-color 0.3s ease;
    padding: 5px;
    font-size: medium;
    font-weight: bolder;
    cursor: pointer;
  }


