
.about-us-container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.a-h1{
    font-size: 30px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: strong;
    text-align: center;
    padding: 10px;
}

.a-h2{
    font-size: 20px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    text-align: center;
    padding: 10px;
}

.a-h3{
    font-size: 15px;
    text-align: center;
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.help-list{
    padding: 20px 20px;
    width: 100%;
}

.help{
    border: 1px solid white;
    padding: 20px 25px;
    border-radius: 10px;
    margin: 3px 0;
    
}