.h1 {
   font-size: 50px;
   font-family: 'Courier New', Courier, monospace;
   font-weight: bolder;
   text-align: center;
   padding-top: 10px;
}

.h2{
  font-size: 30px;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
  text-align: center;
  padding: 10px;
  padding-bottom: 0px;
  /* opacity: 0;
  transition: opacity 3s ease-in-out; */
}

.tran-h2 {
   font-size: 30px;
   font-family: 'Courier New', Courier, monospace;
   font-weight: bold;
   text-align: center;
   padding: 10px;
   padding-bottom: 0px;
   opacity: 0;
   transition: opacity 3s ease-in-out;
}

.expertise{
    padding: 10px;
}

.grid-container {
    display: grid;
    background-color: rgb(11, 16, 85);
    border-radius: 10px;
    border: 2px solid rgb(80, 82, 165);
    /* grid-template-columns: auto auto auto auto; */

}

.logo {
    padding: 5px;
    height: 200px;
    width: 200px;
    align-items: center;
}

.logo img {
    height: 100%;
    width: 100%;
}

.expertise-list {
    padding: 10px;
    text-align: center;
}

/* For small screens (mobile devices) */
@media (max-width: 600px) {
    .grid-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  
  /* For medium screens (tablets) */
  @media (min-width: 601px) and (max-width: 1024px) {
    .grid-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  /* For large screens (desktops) */
  @media (min-width: 1025px) {
    .grid-container {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .product-list{
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
  }
  
  .product{
    height: 200px;
    width: 300px;
    padding: 10px;
    margin: 10px;
    background-color:rgba(29, 18, 71, 0.3);
    border-radius: 5px;
    
  }

  .p-h1{
    font-size: 25px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bolder;
    text-align: center;
    padding: 10px;
    opacity: 1;
   
  }

  .p-h2{
    font-size: 15px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    opacity: 1;
    z-index: 2;
  }

  .p-h3{
    font-size: 15px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    text-align: center;
    color: royalblue;
    padding: 10px;
  }

  .bdiv{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }


  .h1-sub {
    text-align: center;
    padding-bottom: 10px;
    font-size: 10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-weight: bold;
  }
  
  .sub-h2{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: x-large;
    font-family: 'Courier New', Courier, monospace;
  }

  .sub-h3{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 50px;
    font-size: x-large;
    font-family: 'Courier New', Courier, monospace;
    background-color: rgb(22, 18, 71, 0.3);
    border-radius: 5px;
  }

  .contact-button{
    border-radius: 5px; /* Rounded corners */
    padding: 5px;
    font-size: medium;
    font-weight: bolder;
    cursor: pointer;
    color: white;
    text-decoration: none;
    background-color: rgb(0, 0, 156);
    /* color: white; */
    border: 2px solid rgb(11, 12, 99);
  }

  .sub-h4{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .help-btn{
    border-radius: 5px; /* Rounded corners */
    padding: 10px;
    font-size: large;
    font-weight: bold;
    cursor: pointer;
    color: white;
    text-decoration: none;
    background-color: rgb(7, 7, 156);
    margin-top: 30px; 
    border: 2px solid rgb(11, 12, 99);
  }

  .gridcontainer{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .blog-btn{
    border-radius: 5px; /* Rounded corners */
    padding: 10px;
    font-size: larger;
    font-weight: bold;
    cursor: pointer;
    color: white;
    text-decoration: none;
    background-color: rgb(7, 7, 156);
    margin-top: 30px; 
    margin-bottom: 30px;
    border: 2px solid rgb(11, 12, 99);
  }

  .blog-btn:hover{
    background-color: rgb(23, 23, 161);
    color: white;
  }

  .blog-btn-container{
    display: flex;
    align-items: center;
    justify-content: center;
  }


  
  .tran-h2.visible {
    opacity: 1; /* Make the text visible */
  }
  