
.blog-page{
    height: 100vh;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    flex-direction: column;
}

.blog-list{
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: end;
    /* justify-content: center; */
    /* align-items: center; */
    padding: 10px;
}

.blog{
    height: 200px;
    /* width: 300px; */
    padding: 10px;
    margin: 10px;
    background-color:rgba(29, 18, 71, 0.3);
    border-radius: 10px;
    border: 1px solid white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.blog-h{
    font-size: 30px;
    font-family: 'Courier New', Courier, monospace;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    padding-bottom: 0px;

}