/* Main popup container */
.small-popup {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 300px;
    background: #0078d7;
    color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    animation: slideUp 0.5s ease;
  }
  
  /* Popup content */
  .popup-content {
    padding: 15px;
    position: relative;
    font-size: 20px;
    line-height: 1.5;
  }
  
  /* Close button (X) */
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    color: white;
    font-size: 18px;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .close-btn:hover {
    color: #ffb3b3;
  }
  
  /* Animation for popup */
  @keyframes slideUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .survey-btn{
    font-size: large;
    border-radius: 10px;
    background-color: bisque;
    padding: 5px 5px;
    cursor: pointer;
  }