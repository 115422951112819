.contact-header {
    
    padding: 10px 0;
    border-bottom: 3px solid #013d7a;
    display: flex;
    flex-direction: row;
}

.child {
    width: 100%;
    text-align: center;
    color: white;

}


.icon {
    padding: 6px;
    color: white;
}