


.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    background-color: rgb(19, 1, 31);
}

.fordev-logo {
    width: 80px;
    height: 80px;
  
}

.fordev-logo img {
    width: 100%;
    height: 100%;
    border-radius: 50px;
}

.icon {
    color: white;   
    height: 50px;
    width: 50px;
}

.details {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    margin-left: 20px;
    padding: 20px;
}     

.trademark{
 text-align: center;
}