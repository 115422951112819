*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body{
  background-color: rgb(14, 1, 28);
  color: white;
  font-family:'Times New Roman', Times, serif;
}